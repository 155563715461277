.sidebar {
    position: fixed;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    /* box-shadow: 20px 0px 25px 1px black; */
    width: 23%;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    /* overflow: scroll; */
    border-left: 0.2px solid #e1dddd;
    z-index: 20;
    color: var(--bs-body-color);
}

.second-sidebar {
    margin-top: 35px;
    margin-right: 15px;
    flex-direction: column;
    display: flex;
    color: var(--bs-body-color);
    /* overflow: scroll; */
}

.list-sidebar .interist {
    padding-left: 10px;
    color: #131428;

}

.list2-sidebar .interist {
    padding-left: 10px;
    color: #131428;
}

.list-sidebar span {
    font-size: 12px;
    padding-right: 15px;
    padding-top: 5px;
    color: #131428;

}


.list2-sidebar span {
    font-size: 12px;
    padding-right: 15px;
    padding-top: 5px;
    color: #131428;

}

.list-sidebar {
    display: flex;
    flex-direction: row;
    padding: 10px;
    margin: 6px;

}

.list2-sidebar {
    display: flex;
    flex-direction: row;
    padding: 10px;
    margin: 6px;
    margin-right: 20px;
    border-right: 1px solid grey;

}

.headbar-icon {

}

.headbar-div {
    height: 2px;
    width: 95%;
    background-color: black;
    border-radius: 5px;
}

.headbar-text {
    
}

.main-headbar {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 2.5px;
}

.panel-icon {
    padding-top: 10px !important;
}


.header-register {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    position: fixed;
    padding: 10px;
    padding-left: 1rem;
    border-bottom: 1px solid #e6e6e6;
    background-color: white;
    z-index: 10;
    box-shadow: 0px 0px 6px -5px black;
}

.sec-head {
    height: 90%;
    padding: 10px;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 30px;


}

.bred-cramp {
    display: flex;
    color: var(--bs-body-color);
    width: 100%;
}

.curs {
    cursor: pointer;
}

.sizes {
    width: 20%;
    color: aliceblue;
    border-end-start-radius: 100px;
    border-start-end-radius: 100px;
    border-end-end-radius: 10px;
    border-start-start-radius: 10px;

}

.cssImg {
    height: auto;
    width: 60%;
}

.head-iconlabel {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90%;
    width: 28%;
}

.iconlabel {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90%;
    width: 19%;
}

.head-iconlabel span {
    font-size: 1.2rem;
    font-weight: bold;
}


.sizes2 {
    width: 50%;
    padding-right: 15px;
    justify-content: flex-start;
    color: var(--bs-body-color);
}

.bred-cramp text {
    padding-left: 7px;
    font-size: 1rem;
    color: #131428;
}

.fitnes {
    height: auto;
    width: 16%;
}

.falocl {
    border-radius: 50%;
    height: 25px;
    width: 25px;
    background-color: rgb(245 245 245);
}

.bred-cramp svg {

    color: #131428;
}

@media only screen and (max-width : 1100px) {

    .sizes2 {
        padding-right: 25px;

    }
}

@media only screen and (max-width : 1100px) {

    .sizes2 {
        padding-right: 25px;

    }

    .sizes2 {
        width: 46%;

    }

    .bred-cramp text {
        padding-left: 7px;
        font-size: 0.8rem;
    }
}


.active2 .interist,
.active2 span,
.active2 text,
.active2 svg,
.active2 div {

    color: rgb(73, 43, 226) !important;
    /* background-color: rgb(110, 110, 247 , .2); */
}

.act div {
    background-color: rgb(73, 43, 226) !important;
}

.active2 .textactive2 {

}

@media only screen and (max-width : 650px) {

    .sidebar {
        display: none;
        /* position: fixed;
        top: 0;
        left: 50%;
        transform: translateY(-50%);
        width: 100%;
        height: 30%;
        box-shadow: 0px -20px 25px 1px black;
        background-color: white;
        display: flex;
        flex-direction: row;
        justify-content: flex-start; */
    }

    .falocl {
        height: 20px;
        width: 30px;
    }

    .plansfield {
        height: 965px;
        /* margin-top: 260px; */
        padding-right: 0px;

    }

    .plana {
        padding-right: 0px;
        font-size: 10px;
        margin: 6rem 0rem;

        width: 95%;
    }

    .bg-fixet {
        height: 100%;
    }

    .servicesz {
        height: 100vh;
        display: flex;
        justify-content: flex-start;
        width: 95%;
        margin: 6rem 0rem;
    }

    .sizes2 {
        padding-right: 25px;
        width: 98%;
    }

    .hideprops {
        display: none;
    }

    .header-register {
        margin: 0px;
    }

    .sec-head {
        margin: 0px;
        padding: 10px;
    }

}

.active2 a {
    color: rgb(73, 43, 226) !important;

}
.plansfield {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 450px;
    width: 70%;
    margin: 50px;
    position: relative;
    padding-right: 200px;
    margin-top: 6rem;
}

.bg-fixet {
    height: 100vh;
    display: flex;
    justify-content: center;
    width: 60%;
}

.plana {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 560px;
    width: 80%;
    margin: 50px;
    position: relative;
    padding-right: 200px;
    margin-top: 6rem;
    margin-right: 130px;
}

.plan-fix {
    width: 95%;
    height: 100%;
}

.input-map {
    outline: 0px solid black;
    height: 65%;
    width: 90%;
    border-radius: 3px;
    border: 0px solid black;
    padding: 3px 7px;
}

.firsth4 {
    display: flex;
    flex-direction: column;
    text-align: right;
    justify-content: space-evenly;
    align-items: center;
    align-self: flex-start;
    width: 100%;
    height: 5%;
    align-items: baseline;
    padding-right: 30px;
}

.realorfake {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    /* height: 450px;
    width: 50%;
    background-color: grey;
    margin: 50px; */
    /* position: relative; */
}

.map-consoles {
    border: 1px solid #dedcdc;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 450px;
    width: 100%;
    padding-right: 15px;
    border-radius: 10px;
    background-color: white;
}

.wrap-consoles {
    height: 80%;
    width: 95%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.search-style {
    height: 10%;
    width: 45%;
    position: absolute;
    left: 11%;
    top: 9%;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
}

.map-style {
    /* background-color: #f0f0fa; */
    height: 100%;
    width: 100%;
    border-radius: 45px;
    z-index: 1;
}

.butts {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 20%;
    width: 95%;
}

.butts1 {

    display: flex;
    justify-content: center;
    align-items: center;
    height: 80%;
    width: 30%;
}

.btn-1 {
    height: 50%;
    width: 90%;
    outline: 0px solid black;
    border: 0px solid red;
    border-radius: 5px;
    box-shadow: 0px 2px 5px 0.1px black;
}

.bt-color-lightblue {
    background-color: rgb(97, 97, 232);
}

.bt-color-grey {
    background-color: rgb(188, 188, 204);

}

/* .bt-color-grey{
    font-family: ''  ;
} */
.bt-color-lightblue:hover {
    background-color: rgb(76, 76, 235);
}

.bt-color-grey:hover {
    background-color: rgb(128, 128, 135);

}

.selection {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 400px;
    padding: 4px;
    margin: 0px 5px;
    border: 1px solid #dedcdc;
    border-radius: 7px;
    background-color: white;
    width: 100%;
}

.main-let {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: baseline;
}

.sec-let {
    height: 400px;
    width: 40%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    margin-right: 8rem;
    margin-top: 8rem;
    border: 1px solid #d9d5d5;
    border-radius: 5px;
}

.block-let {
    height: 65%;
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.block-p {}

.b-let {
    height: 18%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    border-bottom: 1px solid #d9d5d5;

}

.c-let {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 16%;
    width: 90%;
}

.bord {
    /* border-left: 1px solid #c2c2c2; */
    padding-left: 6px;
    font-size: 12px;
    width: 45%;

}

.nobord {
    cursor: pointer;
    font-size: 12px;
    width: 40%;

}

.nobord:hover {
    cursor: pointer;
    color: #1818a9;
}

.subbtn {
    height: 55%;
    width: 50%;
    border: 0px;
    /* outline: 1px solid black; */
    border-radius: 9px;
    color: #1818a9;
    box-shadow: 1px 1px 1px 1px grey;
}

.subbtn:hover {
    height: 57%;
    width: 52%;
    background-color: #1818a9;
    color: white;
    box-shadow: 1px 1px 1px 1px grey;
}





@media only screen and (max-width : 1200px) {

    .entering2,
    .entering {
        padding-right: 135px;
    }
}


@media only screen and (max-width : 1100px) {

    .search-style {
        width: 25%;
        /* border-radius: 20px; */
    }

    .input-map {
        border-radius: 20px;
    }

    .entering2,
    .entering {
        padding-right: 135px;
    }
}


@media only screen and (max-width : 910px) {

    .realorfake {
        flex-direction: column;
        /* padding-bottom: 20px; */
    }

    .entering2,
    .entering {
        padding-right: 100px;
    }

    .selection {
        margin-bottom: 30px;
    }
}

@media only screen and (max-width : 750px) {
    .res-size {
        font-size: 14px;
    }
}

@media only screen and (max-width : 650px) {

    .search-style {
        width: 45%;
        width: 45%;
        left: 30%;
        top: 8%;

    }

    .input-map {
        border-radius: 5px;

    }

    .plan_fix {
        width: 98%;
    }

    .confirm-span {
        font-size: 12px;

    }

    .line-hey {
        line-height: 1.5rem;
    }

    .services {
        margin-bottom: 0px;
    }
}


.planh4 {
    display: flex;
    flex-direction: column;
    text-align: right;
    justify-content: space-evenly;
    align-items: center;
    align-self: flex-start;
    width: 100%;
    height: 5%;
    align-items: baseline;
    margin-bottom: 20px;

}

.map-consoles {}



.first-selection {
    height: 450px;

}

.divbutt {
    height: 20%;
    width: 90%;
    display: flex;
    justify-content: flex-end;
}

.divbutt a {
    width: 60%;
    height: 88%;
}

.f-but {
    height: 100%;
    width: 100%;
}

.items-plan {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 50%;
    width: 90%;
}

.font-plans {
    width: 99%;
}

.services {
    width: 100%;
    height: 87%;

}

.img-plan {
    height: 100%;
}

.list-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    height: 23%;
    width: 100%;
}

.input-label {
    height: 90%;
    width: 48%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding: 5px;
}

.label-form,
.input-form {
    margin: 3px;
}

.input-form {
    border: 1px solid #c2c2c2;
    height: 22%;
    width: 75%;
    border-radius: 3px;
    outline: 0px solid grey;
}

.btn-list-input {
    display: flex;
    height: 10%;
    width: 95%;
    justify-content: flex-end;
    align-items: center;
}

.aqd-contract {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: justify;
    height: 100%;
    width: 52%;
    margin-left: 1rem;
    font-size: 16px;
    color: #5d5d5d;
}


.aqd-contract {}

.label-form {
    font-size: 10px;
}

.wid-94 {
    width: 94%;
}

.wid-90 {
    width: 90%;
}

.height-600 {
    height: 660px;
    margin-top: 110px;
}

.wid-20 {
    height: 11%;
}

.banner-plan {
    height: 4%;
    width: 15%;
    border-bottom: 1px solid black;
    text-align: center;
}

.banner-planreal {
    height: 6%;
    width: 15%;
    border-bottom: 1px solid black;
    text-align: center;
}

.heigh-9 {
    height: 90% !important;
}

.bord-ght {
    border-color: lightgrey;
    color: #c3c3c3;
}

.adding {
    justify-content: center;
}

.wid-70 {
    width: 70%;
    min-width: 300px;
}

.fade {
    transition: opacity 3s linear;
}

.callCenter {
    height: 3rem;
    background-color: whitesmoke;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
}

.callCenter text {
    font-size: 16px;
    font-weight: bold;
}

.planselect {
    color: blue;
}

.planselect {
    color: blue;
}

.planselect {
    color: blue;
}

.planselect {
    color: blue;
}

.planselect {
    color: blue;
}

.servicesz {
    width: 97% !important;
    height: 530px;
}

.plan-fill {
    width: 81%;
    height: 525px;
}

.khat {
    height: 40%;
    width: 1px;
    border: 1px solid #dbd3d3;
}

.addimg {
    height: 82%;
    width: 90%;
}

.imgLoaded {
    align-items: center;
    justify-content: flex-end;
}

.imageShow {
    height: 90%;
    width: 80%;
    border: 0.5px solid #f0f0f0;
    border-radius: 15px;
}

.imloaded1 {
    align-items: center;
}

.showImmagecart {
    height: auto;
    width: 95%;
    border-radius: 15px;
    /* border: 1px solid grey; */
}

.pruficon {
    margin-left: 10px;
    border-color: #d4cece;
}

.re-marg {
    margin-bottom: 0px;
    padding-bottom: 0px;
}

.font-plans text,
.font-plans span {
    color: black;
}

.heigh-100 {
    height: 100%;
}

.btnwid {
    width: 55%;
}

.showing {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 90%;
    width: 100%;
}

.child-showing {
    height: 80%;
    width: 85%;
    background-color: #fcfcfc;
    border-radius: 5px;
    padding: 5px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    cursor: pointer;
}

.entering {
    height: 650px;
}

.first-entering {
    height: 90%;
}

.entering2 {
    height: 900px;
}

.first-entering2 {
    height: 95%;
}

.wid-101 {
    width: 99%;
}

.wid-95 {
    width: 95%;
}

.edit-image {}

.btn-50 {
    height: 50%;
}

.col-bl {
    color: black;
    border-color: black;
}

.border-balck {
    border: 1px solid black !important;
}

.col-bl:hover {
    color: black !important;
    background-color: #f4f3fe !important;
    border-color: rgb(54, 54, 54) !important;
}

.risking {
    width: 95%;
    height: 90%;
}

.resik {
    justify-content: flex-end;
}

/* @media only screen and (max-width : 650px) {
    .wid-70 {
        height: 95% ;
    }

} */

@media only screen and (max-width : 1100px) {
    .plan-fill {
        width: 90%;
    }

    .plana {
        width: 87%;
        margin-right: 5rem;
    }

    .bg-fixet {
        width: 80%;
    }

    .entering {
        margin-right: 8rem;
    }

    .entering2 {
        margin-right: 130px !important;
    }
}

@media only screen and (max-width : 910px) {
    .plan-fill {
        height: 1120px;
        padding-right: 160px;
    }

    .bg-fixet {
        width: 85%;
    }

    .plana {
        width: 91%;
        margin-right: 2rem;
    }

    .entering {
        margin-right: 8rem;
    }

    .entering2 {
        margin-right: 130px !important;
    }
}

@media only screen and (max-width : 800px) {
    .plana {
        width: 94%;
        margin-right: 0rem;
    }

    .bg-fixet {
        width: 95%;
    }

    .entering {
        margin-right: 8rem;
    }

    .entering2 {
        margin-right: 130px !important;
    }
}

@media only screen and (max-width : 650px) {
    .entering2 {
        margin-right: 0px !important;
    }
    .aqd-contract {
        font-size: 12px;
    }
}

.read-contract {
    width: 80%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 2rem;
}
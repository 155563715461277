.last-page {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    padding: 0.2rem 1rem;
    height: 80%;
    width: 70%;
    position: fixed;
    left: 15%;
    top: 10%;
    background-color: rgb(242 248 255);
    border: 1px solid black;
    box-sizing: border-box;
    box-shadow: 0px 0px 7px -5px rgb(167, 167, 167);
    border-radius: 7px 10%;
}

.head-lastpage {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 19%;
    width: 80%;
    border-bottom: 1px solid black;
    padding-bottom: 7px;
}

.body-lastpage {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    width: 95%;
    height: 82%;
    padding: 1rem 2rem;
}

.logo7 {
    height: auto;
    width: 50%;
}

.logo-lastpage {
    height: 20%;
    width: 90%;
    display: flex;
    align-items: baseline;
    justify-content: center;
    text-align: center;
}

.text-lastpage {
    height: 75%;
    width: 85%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    flex-direction: column;
}

.text-lastpage p {
    margin: 25px 0rem ;
    font-size: 20px;
}

.logo-lastpage h5 {
    font-size: 22px;
    font-weight: bold;
    width: 100%;

}

@media only screen and (max-width : 1050px) {
    .last-page {
        width: 80%;
        left: 10%
    }
}

@media only screen and (max-width : 850px) {
    .last-page {
        width: 90%;
        left: 5%;
    }
}

@media only screen and (max-width : 650px) {
    .last-page {
        width: 96%;
        left: 2%;
        height: 70%;
        top: 7%;
    }

    .body-lastpage {
        width: 100%;
        padding: 1rem 0rem;
    }

    .head-lastpage {
        width: 90%;
    }

    .logo-lastpage {
        height: 10%;
        width: 95%;
    }
    .text-lastpage p {
        font-size: 20px;
    }

    .logo7 {
        height: auto;
        width: 100%;
    }
    .text-lastpage {
        width: 98%;
    }
}
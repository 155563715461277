.searchFill {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    height: 125px;
    padding: 13px 25px;
}

#chart {
    max-width: 1100px;
    margin: 35px auto;
    width: 100%;

}

#chart1 {
    max-width: 1100px;
    margin: 35px auto;
    width: 100%;
}

#chart2 {
    max-width: 1100px;
    margin: 35px auto;
    width: 100%;
}

.filters {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 85%;
    width: 95%;
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 15px;

}

.tbody-holydays {
    display: flex;
    justify-content: center;
    align-items: center;
}

.fst {
    width: 30%;
}

.secd {
    width: 30%;
}

.filters label,
.filters select {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.filters input {}

.firstava {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.headava {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.7rem;
}

.sermaintain {
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    padding-right: 1rem;
}

.divList {
    display: flex;
    height: 3rem;
}

.takIcon {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    list-style: none;
}

.manageicon {}

.fabars-icon {
    display: none;
}

.flex-align {
    align-items: center;
}

@media only screen and (max-width : 1200px) {
    .fabars-icon {
        display: block;
    }

    .tranz {
        transition: all 0.3s linear !important;
        transform: translate3d(0, 0, 0) !important;

    }
}

@media only screen and (max-width : 950px) {
    #chart {
        width: 415px;
    }

    #chart1 {
        width: 415px;
    }

    #chart2 {
        width: 415px;
    }
}

@media only screen and (max-width : 850px) {
    #chart {
        width: 540px;
    }

    #chart1 {
        width: 540px;
    }

    #chart2 {
        width: 540px;
    }

    .miuzn {
        margin-top: 77rem;
    }
}


@media only screen and (max-width : 650px) {
    #chart {
        width: 390px;
    }

    #chart1 {
        width: 390px;
    }

    #chart2 {
        width: 390px;
    }

    .miuzn {
        margin-top: 60rem;
    }
}


.apexcharts-toolbar {
    display: none;
}

.inner1-contract {
    position: absolute;
    display: none;
}

.inner2-contract {
    position: absolute;
    display: none;
}

.inner3-contract {
    position: absolute;
    display: none;
}

.inner4-contract {
    position: absolute;
    display: none;
}

.inner5-contract {
    position: absolute;
    display: none;
}

.inner6-contract {
    position: absolute;
    display: none;
}


/* @media only screen and (max-width : 1440px) {
    .inner1-contract {
        left: 65%;
        top: 353px;
        font-size: 22px;
        font-weight: bold;
    }

    .inner2-contract {
        left: 43%;
        top: 352px;
        font-size: 14px;
        font-weight: bold;
    }

    .inner3-contract {
        left: 31%;
        top: 353px;
        font-size: 14px;
        font-weight: bold;
    }

    .inner4-contract {
        left: 65%;
        top: 382px;
        font-size: 22px;
        font-weight: bold;
    }

    .inner5-contract {
        left: 51%;
        top: 381px;
        font-size: 14px;
        font-weight: bold;
    }

    .inner6-contract {
        left: 42%;
        top: 381px;
        font-size: 14px;
        font-weight: bold;
    }
}


@media only screen and (max-width : 1025px) {
    .inner1-contract {
        left: 74%;
        top: 437px;
        font-size: 22px;
        font-weight: bold;
    }

    .inner2-contract {
        left: 40%;
        top: 432px;
        font-size: 16px;
        font-weight: bold;
    }

    .inner3-contract {
        left: 24%;
        top: 435px;
        font-size: 16px;
        font-weight: bold;
    }

    .inner4-contract {
        left: 73%;
        top: 474px;
        font-size: 22px;
        font-weight: bold;
    }

    .inner5-contract {
        left: 53%;
        top: 470px;
        font-size: 16px;
        font-weight: bold;
    }

    .inner6-contract {
        left: 40%;
        top: 471px;
        font-size: 16px;
        font-weight: bold;
    }
} */

@media only screen and (max-width : 770px) {
    .inner1-contract {
        left: 73%;
        top: 343px;
        font-size: 18px;
        font-weight: bold;
    }

    .inner2-contract {
        left: 41%;
        top: 342px;
        font-size: 14px;
        font-weight: bold;
    }

    .inner3-contract {
        left: 24%;
        top: 341px;
        font-size: 14px;
        font-weight: bold;
    }

    .inner4-contract {
        left: 73%;
        top: 371px;
        font-size: 18px;
        font-weight: bold;
    }

    .inner5-contract {
        left: 52%;
        top: 368px;
        font-size: 12px;
        font-weight: bold;
    }

    .inner6-contract {
        left: 40%;
        top: 367px;
        font-size: 12px;
        font-weight: bold;
    }
}

@media only screen and (max-width : 500px) {
    .inner1-contract {
        display: block;
        left: 70%;
        top: 205px;
        font-size: 12px;
        font-weight: bold;
    }

    .inner2-contract {
        display: block;
        left: 40%;
        top: 204px;
        font-size: 9px;
        font-weight: bold;
    }

    .inner3-contract {
        display: block;
        left: 24%;
        top: 206px;
        font-size: 8px;
        font-weight: bold;
    }

    .inner4-contract {
        display: block;
        left: 70%;
        top: 219px;
        font-size: 12px;
        font-weight: bold;
    }

    .inner5-contract {
        display: block;
        left: 52%;
        top: 219px;
        font-size: 8px;
        font-weight: bold;
    }

    .inner6-contract {
        display: block;
        left: 39%;
        top: 219px;
        font-size: 8px;
        font-weight: bold;
    }
}
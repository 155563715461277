.select-items {
height: 30%;
width: 98%;
padding: 7px;
}
.maintem {
    height: 48%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
.dire {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.btndire {
    display: flex;
    justify-content: flex-end;
}

.btndire button {
    height: 80%;
    width: 35%;
}
@media only screen and (max-width : 650px) {
    .btndire button {
        height: 60%;
    }

}
.img-plan {
    width: 98%;
    overflow: hidden;
}
.img-plan img {
    height: 100%;
    width: 100%;
    border-radius: 4px;
    transform: scaleY(1.9);
}
.inputsizes {
    width: 42%;
    margin-left: 20px;
}
.curse-point {
    cursor: pointer;
}

.inputsizes3 {
    width: 25%;
    margin-left: 20px;
}

.head-flexible {
    display: flex;
    flex-direction: row;
}

.btn-margin {
    margin: 1rem 0.375rem 0 0 !important;
}

.okdade {
    display: flex;
    justify-content: center;
}

.head-holy {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.holybtn {
    margin: 0px !important;
    width: 50%;

}

.hobtn {
    margin: 0px !important;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 50%;
}

.marg-top {
    margin-top: 10px;
}

.marg-ri {
    margin-right: 10px;
    margin-bottom: 5px;
    width: 50%;
}

.manageicon {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.managing label {
    font-size: 14px;
}

.filled {
    padding: 20px 25px 10px 10px;
    font-size: 14px;
}

.inputmanaging {
    width: 40%;
    margin-left: 15px;
}

.btnmanaging {
    margin-top: 27px;
}

.orders-image {
    height: auto;
    width: 100%;
}

.orders-image1 {
    height: auto;
    width: 61%;
}

.reacttion {
    padding: 0px 0px;
    padding-block: 1px;
    padding-inline: 6px;

}

.btngroup-green {
    background-color: lightgreen;
}

.btngroup-red {
    background-color: lightsalmon;
}

.btngroup-orange {
    background-color: lightyellow;
}

.product-list-responsive {
    border: 1px solid #e1e1e1 !important;
    border-radius: 8px;
    /* margin: 1rem; */
    margin: 1rem 1% 0rem;
    box-shadow: 0px 0px 10px -7px #b9b9b9;
}

.Paginationclass {
    margin-top: 1rem !important;
}

.color-product {
    color: #131428 !important;
}

.main-line-space {
    display: flex;
    justify-content: space-around;
    align-items: center;
    vertical-align: middle;
    margin: 2rem 0;
}

.show-image-addorders {
    width: 30%;
    height: 100px;
    border: 1px dashed #000000;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    overflow: hidden;
    font-size: 14px;
}

.self-image-addorders {
    height: 95%;
    width: 95%;
    border-radius: 0.5rem;
}

@media only screen and (max-width : 1200px) {
    .holybtn {
        margin: 0px !important;
        /* width: 80%; */
    }

}

@media only screen and (max-width : 650px) {
    .inputsizes {
        width: 55%;
        margin-left: 20px;
    }

    .head-flexible {
        flex-wrap: wrap;
    }

    .inputsizes3 {
        width: 90%;
    }

    .addorders-btn-res {
        width: 60% !important;
    }

    .wid-full {
        width: 100%;
    }
    .wid-85 {
        width: 92%;
    }

    .show-image-addorders {
        width: 48%;
        font-size: 10px;
    }
    .btn-fix-allsize {
        width: 90%;
    }
    .marg-ri {
        width: 102%;
    }
    .wid-95-650{
        width: 95%;
    }
}
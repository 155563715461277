.App {
  display: flex;
  justify-content: center;
  height: 100vh;
}

.app-backdrop {
  height: 100%;
  width: 100%;
  background: white;
  position: fixed;
  z-index: -1;
}


.product-details-modal {
    height: 70%;
    top: 7%;
    background-color: #FDFEFE;
    border: 1px solid black;
    border-radius: 10px;
    position: fixed;
    left: 10%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 80%;
    flex-direction: column;
    z-index: 2999;
}

.product-img-modal {
    height: 110px;
    width: 70%;
}

.flex-lable-image {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.exre {
    height: 50%;
    width: 75%;
    border: 1px solid #e1e1e1;
    background-color: transparent;
    border-radius: 4px;
    color: black;
}



@media only screen and (max-width : 1100px) {}

@media only screen and (max-width : 850px) {
    .product-details-modal {
        width: 88%;
        left: 6%;
    }
}

@media only screen and (max-width : 650px) {

    /* .res-650 {
        width: 96%;
    } */
    .product-details-modal {
        width: 94%;
        left: 3%;
    }
}
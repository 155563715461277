.register-box {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-evenly;
    align-items: center;
    height: 410px;
    width: 45%;
    position: relative;
    border-radius: 10px;
    border: .3px solid rgb(162 162 162);
    /* box-shadow: 0px 0px 2px .5px grey; */
    background-color: white;

}



.mar-45 {
    margin-top: 35px;
}

.register-box:hover {
    /* box-shadow: 0px 0px 0px 0px grey; */
    /* border: .3px solid rgb(177, 166, 166); */

}
.confirm-span{
    padding: 6px 10px 5px 10px;
    background-color: #efefef;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    font-size: 14px;
}
.line-hey{
    line-height: 2rem;
}

.pishlist {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.static {
    width: 46%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.changeble {
    width: 55%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.imghandle {
    height: 13%;
    /* height: 80%;  */
    /* border-radius: 50%; */
}

.first-foot {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    width: 45%;
    margin-top: 20px;
    height: 2rem;
    margin-bottom: 1rem;
}

.secfood span {
    color: #131428;
    cursor: pointer;
}

.secfood span:hover {
    color: #323560;
}

.linefood {
    height: 100%;
    width: 1px;
    border: 1px solid rgb(211, 208, 208);
}

.firstfield {
    /* background-color: rgb(165, 31, 31); */
    height: 17%;
    width: 97%;
    display: flex;
    justify-content: space-around;
    align-items: baseline;
    flex-direction: column;
    padding: 0px 5px;
}

.btnfield {
    /* background-color: rgb(165, 31, 31); */
    height: 20%;
    width: 99%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0px 5px;
}

.inputfield {
    /* background-color: rgb(165, 31, 31); */
    height: 20%;
    width: 97%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    /* padding: 0px 5px ; */
}

.que-box {
    height: 100%;
    width: 99%;
    /* margin:0px; */
    /* background-color: rgb(61, 53, 168); */
    flex-direction: column;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 5px 15px 5px 10px;
}

.inputfield input {
    width: 90%;
    height: 40%;
    outline: 0px;
    border: 1px solid lightblue;
    border-radius: 3px;
}

.inputfield label {
    font-size: 14px;
    align-self: baseline;
    padding-right: 10px;
}

.input-control:focus {
    border-color: #7367f0;
    padding: 2px 10px;
    border-radius: 3px;
}

.input-control {
    padding: 3px 7px;
}

.edi {
    display: flex;
    flex-direction: row-reverse;
}
.countdown{
    padding-left: 10px;
}

.btn-phone {
    width: 95%;
    height: 45%;
    box-shadow: 0px 0px 17px -9px black;
    box-shadow: 0 0 1rem 0px rgba(165, 163, 174, 0.3);
}

.firstfield p {
    font-size: 16px;
}

.btn-plan {
    width: 60%;
    height: 85%;
}

.large {
    height: 45%;
    width: 80%;
}

.btncolor-updown {
    background-color: white !important;
    color: #131428;
}

.hfirstfieldsub {
    height: 10%;
    width: 97%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    padding: 0px 5px;
}

.firstfieldsub {
    height: 30%;
    width: 97%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    padding: 0px 5px;
}
.firstfieldsubcode{
    height: 10%;
    width: 97%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    padding: 0px 5px;
}

.inputfieldsub {
    height: 20%;
    width: 97%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
}

@media only screen and (max-width : 1200px) {

    .imghandle {
        height: auto;
        width: 63%;
    }
}

@media only screen and (max-width : 820px) {

    .register-box {
        flex-direction: column;
        margin: 0px;
        width: 85%;
        height: 530px;
    }

    .static {
        width: 85%;
        height: 19%;
    }

    .changeble {
        width: 100%;
        height: 80%;
    }

    .imghandle {
        height: auto;
        width: 40%;
    }

    .firstfield p {
        font-size: 1rem;
    }
}


@media only screen and (max-width : 650px) {

    .register-box {
        flex-direction: column;
        margin: 0px;
        width: 85%;

    }
    .pishlist {
        height: 90vh ;
    }

    .static {
        width: 85%;
        height: 19%;
    }

    .changeble {
        width: 100%;
        height: 80%;
    }

    .imghandle {
        height: auto;
        width: 63%;
    }

    .firstfield p {
        font-size: 1rem;
    }
    .error-valid {
        font-size: 9px;
    }
}

.error-valid {
    color: #ff4444;
}

.firstfield p {
    color: black;
}

label {
    color: black;
}
.hfirstfieldsub span ,
.firstfieldsub p ,
.inputfieldsub label ,
.firstfieldsub p ,
.firstfieldsubcode p {
    color: black;
}
.hei-50 {
    height: 50%;
}
@media only screen and (max-width : 650px) {

    .secfood span {
        font-size: 12px
    }
    
    .margin-none {
        margin-top: 10px;
    }
    .mar-45 {
        margin-top: 0px;
    }

}
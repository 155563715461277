.backdrop {
    height: 100%;
    width: 100%;
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: 2001;
    background-color: rgba(54, 54, 54, 0.75);
}

.backdrop-loadings {
    display: flex;
    position: fixed;
    height: 100vh;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: rgba(200, 200, 200, 0.5);
    z-index: 3000;
}

.sidebar-backdrop {
    height: 100%;
    width: 100%;
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: 1001;
    background-color: rgba(54, 54, 54, 0.75);
    opacity: 0;
    transform: translateX(100vw);
}

.opacity-backdrop {
    transform: translateX(0);
    opacity: 1;
    transition: opacity 0.3s linear;

}

.holy-modal {
    position: fixed;
    /* left: 27%; */
    top: 2%;
    left: 22.5%;
    height: 70%;
    width: 55%;
    z-index: 2002;
    background-color: rgb(253, 253, 253);
    border-radius: 4px;
    border: 1px solid rgb(222, 222, 222);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 15px;
    flex-direction: column;
}

.ho-modal label {
    font-size: 10px;
}

.mob-all-hide {
    display: none;
}

.product-modal {
    position: fixed;
    /* left: 27%; */
    top: 2%;
    left: 15%;
    height: 90%;
    width: 70%;
    z-index: 2002;
    background-color: rgb(253, 253, 253);
    border-radius: 4px;
    border: 1px solid rgb(222, 222, 222);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 15px;
    flex-direction: column;
    box-sizing: border-box;
    overflow-y: auto;
}

.pro-modal {
    height: 45%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-top: 0.6rem;
}

.pro-modalbtn {
    height: 45%;
    width: 100%;
    padding-top: 0.6rem;
}

.div-ndar {
    height: 18%;
    width: 49%;
    background-color: #ffffff;
    border: 1px solid #919191;
    /* margin-right: 1rem; */
    border-radius: 4px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-right: 1rem;
}

.mar-ri-1 {
    margin-right: 2%;
}

.div-ndar span {
    margin-left: 8px;
    font-size: 14px;
}

.late label {
    font-size: 12px;
}

.table-fix {
    text-align: center;
    font-size: 10px;
}

.ticket-modal {
    position: fixed;
    /* left: 3%; */
    top: 10%;
    left: 37%;
    height: 55%;
    padding: 5px 15px;
    width: 26%;
    z-index: 2002;
    background-color: rgb(253, 253, 253);
    border: 1px dotted grey;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;

}

.flexible {}

.orders-modal {
    position: fixed;
    top: 3%;
    left: 25%;
    height: 85%;
    width: 50%;
    z-index: 2002;
    background-color: rgb(253, 253, 253);
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 15px;
}

.fon {
    font-size: 14px;
}

.modal-image {
    background-color: #f7f7f7;
    position: fixed;
    height: 43%;
    width: 35%;
    top: 25%;
    border-radius: 3px;
    border: 0.1px solid #000000;
    z-index: 2002;
    left: 32.5%;
}

.modimg-head {

    width: 100%;
    height: 25%;
    padding: 10px 30px;
    display: flex;
    align-items: center;
}

.fo-co-black {
    color: black;
}

.modimg-body {
    height: 62%;
    width: 100%;
    padding: 10px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.children-Modal {
    height: 85%;
    width: 85%;
    border-radius: 4px;
    border: 1px dashed #c6bdbd;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loc {
    position: absolute;
    left: 3px;
    top: 5px;

}

.editIcon {
    height: 40px;
    width: 40px;
    background-color: #f8f8f8;
    border: 1px solid black;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 7%;
    bottom: 6%;
}

.ord-modal {
    display: flex;
    /* justify-content: center;
    align-items: center; */
    flex-direction: row;
}

.name-odd {
    width: 99%;
}

.hemodal {
    height: 10%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-ticket {
    height: 100%;
    width: 100%;
    display: flex;
    border-bottom: 0.5px solid grey;
    justify-content: flex-end;
}

.ptop-text {
    width: 80%;
}

.ptop-icon {
    width: 10%;
}

.tickethead-prop {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
}

.name-odd label {
    font-size: 12px;
}

.name-ord label {
    font-size: 14px;
}

.name-ord {
    width: 33%;
    margin-left: 6px;
}

.longname-ord {
    width: 65%;
    margin-left: 6px;
}

.longname-ord label {
    font-size: 14px;
}

.underbtn {
    height: 10%;
    display: flex;
    align-items: center;
    padding: 0px 10px;
}

.selectgro {
    width: 50%;
}

.checkgro {
    height: 100%;
    width: 48%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.unchackgro {
    height: 70%;
    width: 80%;
    border: 1px dashed grey;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.unchackgro:last-child {
    font-size: 10px;
}

.form-check label {
    font-size: 14px;
    font-weight: normal;
}

.underbtn button {
    height: 75%;
    width: 35%;
}

.overbtn {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.ho-modal {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    width: 100%;
}

.late {}

.heightbtn {
    height: 37%;
}

.heightarea {
    height: 13%;
}

.widareas {
    width: 100%;
}

.wid {
    width: 95%;
    margin-left: 7px;
}

.wid1 {
    width: 59%;
    margin-left: 7px;
}

.wid2 {
    width: 35%;
    margin-left: 7px;
}

.widbtn {
    width: 100%;
}

.widbtn button {
    width: 30%;
    height: 60%;
}

.tick-modal {
    height: 12%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tick-modal button {

    height: 75%;
    width: 40%;
}

.custom-mine {
    border: 0px solid;
    border-radius: 3px;
    background-color: #151230;
    color: white;
}

.custom-mine:hover {
    border: 0px solid;
    border-radius: 3px;
    background-color: #060514;
    color: white;
}

.custom-mine:active {
    border: 0px solid !important;
    border-radius: 3px !important;
    background-color: #060514 !important;
    color: white !important;
}

.custom-mine:disabled {
    border: 0px solid;
    border-radius: 3px;
    background-color: #060514;
    color: white;
}

.custom-mine:focus {
    border: 0px solid;
    border-radius: 3px;
    background-color: #060514;
    color: white;
}

.badge2 {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 90%;
}

.inner-badge {
    background-color: green;
    height: 35px;
    width: 100px;
    border: 1px solid lightgreen;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}


@media only screen and (max-width : 1200px) {
    .modal-image {
        width: 45%;
        top: 15%;
        left: 27.5%;
    }

    .orders-modal {
        width: 65%;
        left: 22.5%;
    }

    .product-modal {
        left: 10%;
        width: 80%;
    }

    .holy-modal {
        width: 65%;
        left: 17.5%;
    }
}

@media only screen and (max-width : 850px) {
    .modal-image {
        width: 55%;
        top: 15%;
        left: 22.5%;
    }

    .holy-modal {
        width: 80%;
        left: 10%;
    }

    .ticket-modal {
        width: 39%;
        left: 30.5%;
    }

    .orders-modal {
        width: 77%;
        left: 11.5%;
    }

    .product-modal {
        left: 1%;
        width: 98%;
    }
}

@media only screen and (max-width : 650px) {
    .modal-image {
        width: 85%;
        top: 5%;
        left: 7.5%;
    }

    .mob-all-hide {
        display: table-row;
    }

    .holy-modal {
        width: 90%;
        left: 5%;
        height: 50%;
    }

    .ticket-modal {
        width: 79%;
        left: 10.5%;
    }

    .orders-modal {
        width: 90%;
        left: 5%;
    }

    .underbtn button {
        height: 70%;
        width: 60%;
    }

    .product-modal {
        left: 1%;
        width: 98%;
    }

    .widbtn button {
        width: 40%;
        height: 60%;
    }

    .mob-res-hide {
        display: none;
    }

    /* .mob-res-font {
        font-size: 14px;
    } */
    .mob-res-button-flex {
        display: flex;
        flex-direction: column;
        height: 70px;
        width: 100px;
    }

    .mob-res-button {
        margin-top: 4px;
        border-radius: 10px !important;
    }

    .orders-image1 {
        height: auto;
        width: 75%;
        border-bottom: 1px dashed #2e8ed3;
    }

    .div-ndar span {
        font-size: 14px;
        margin-top: 0.25rem;
    }

    .finale-productmodal-650 {
        flex-direction: column;
    }
}




.input-picker--input {
    background-color: white !important;
}